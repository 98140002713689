import * as React from 'react';
import { useEffect, useRef } from 'react';
import { FieldsMappingContextProvider } from './Context';
import { Configure, HitsPerPage, Pagination, ScrollTo } from 'react-instantsearch-dom';
import { getEnabled, sortByWeight } from './FacetedSearchSidebar';
import { FacetedSearchRefinementList } from './filters/FacetedSearchRefinementList';
import { FacetedSearchClearFilters } from './FacetedSearchClearFilters';
import { FacetedSearchPills } from './FacetedSearchPills';

function useClickOutside(ref, handler) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event.target);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}

const CompactModeFilter = (props) => {
  const {filters, disabledFilters, label} = props;
  const ref = useRef(null);

  const handleClickOutside = (target) => {
    if (ref.current) {
      if (target.classList.contains('filters__reset')) {
        return;
      }

      const dropdown = ref.current.parentElement.querySelector('.filters__wrapper');
      dropdown.classList.add('d-none');
    }
  };

  useClickOutside(ref, handleClickOutside);

  const handleClick = (e) => {
    const dropdown = e.target.parentElement.querySelector('.filters__wrapper');
    dropdown.classList.toggle('d-none');
  }

  const activeFilters = getEnabled(filters, disabledFilters)
    .filter(f => f.compactMode === true);

  if (!activeFilters) {
    return <></>;
  }

  activeFilters.sort(sortByWeight);

  return (
    <div className='filters'>
      <p className='filters__label p--medium fw-bold mb-0'>Filter by:</p>
      <div className='filters__widgets'>
        <button className='p--medium filters__dropdown' onClick={handleClick}>{label}</button>
        <div className='filters__wrapper d-none' ref={ref}>
          <div className='compact-filter'>
            {activeFilters.slice(0,1).map((filter, key) => {
              let el = <div key={key}></div>;
              switch (filter.widget) {
                case 'checkboxes':
                  el = <FacetedSearchRefinementList
                    key={key}
                    showAll={true}
                    header={filter.label || filter.fieldName}
                    attributeName={filter.fieldName}/>;
                  break;
              }
              return el;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FacetedSearchLayoutCompact = (props) => {
  const {
    filterBy,
    title,
    filters,
    disabledFilters,
    quickFiltersRef,
    cssClasses,
    cssClassesHeading,
    cssClassesStats,
    itemsPerPage,
    SearchResults,
    viewMode,
    fieldsMapping,
    compactModeDropdownLabel,
    compactModeCtaText,
    compactModeCtaUrl,
  } = props;

  cssClassesHeading.push('h--medium');

  return <>
    {filterBy && filterBy.length && <Configure filters={filterBy}/>}
    <div className="container faceted-filters faceted-filters--compact">
      <div className="row">
        {title && <div className="col-lg-12 col-md-12 col-sm-12">
          <h2 className={cssClassesHeading.join(' ')}>{title}</h2>
        </div>}
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='compact-filter-container'>
            <div className="compact-filter-container__filter">
              <div className={cssClassesStats.join(' ')}>
                <CompactModeFilter
                  filters={filters}
                  disabledFilters={disabledFilters}
                  label={compactModeDropdownLabel}
                />
                <HitsPerPage
                  defaultRefinement={itemsPerPage}
                  items={[{value: itemsPerPage, label: `${itemsPerPage} items per page`}]}/>
              </div>
            </div>
            <div className="compact-filter-container__link">
              <div className='col-lg-6 col-md-12 col-sm-12 view-all-link'>
                <div className='d-inline-flex'>
                  <a href={compactModeCtaUrl} className='link-cta text-transform-unset'>{compactModeCtaText}</a>
                </div>
              </div>
            </div>
            <div className="compact-filter-container__selected">
              <div className='faceted-search-pills-container'>
                <FacetedSearchClearFilters/>
                <FacetedSearchPills filters={filters} container={quickFiltersRef}/>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className={cssClasses.join(' ')}>
            <FieldsMappingContextProvider>
              <ScrollTo>
                <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
              </ScrollTo>
            </FieldsMappingContextProvider>
          </div>
          <div className="faceted-filters__pagination">
            <Pagination showFirst={true} showLast={true} translations={{previous: "Previous", next: "Next"}}/>
          </div>
        </div>
      </div>
    </div>
  </>
};
