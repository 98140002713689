import * as React from 'react';
import {useState} from 'react';
import {IconClose, IconFilter} from './helper';
import {FacetedSearchBox} from './filters/FacetedSearchBox';
import {FacetedSearchRefinementList} from './filters/FacetedSearchRefinementList';
import {SortBy} from 'react-instantsearch-dom';
import {FacetedSearchMenuSelect} from './filters/FacetedSearchMenuSelect';

const filterState = {
  none: undefined,
  sortBy: 'sortBy',
  filter: 'filter',
  collapsed: 'collapsed',
}

export const sortByWeight = (a, b) => (a.weight > b.weight) ? 1 : (b.weight > a.weight) ? -1 : 0;
export const getEnabled = (config, disabledFilters = []) => {
  const enabledItems = [];
  if (!config) {
    return enabledItems;
  }
  for (const [, filterDefinition] of Object.entries(config)) {
    if (!filterDefinition.enabled) {
      continue;
    }
    if (Array.isArray(disabledFilters) && disabledFilters.includes(filterDefinition.fieldName)) {
      continue;
    }
    enabledItems.push(filterDefinition);
  }

  return enabledItems;
};

/**
 * Handles filters toggling.
 *
 * @param {String | undefined} currentState
 * @param {Function} setter
 * @param {String} state
 */
const handleFiltersToggling = (currentState, setter, state) => {
  if (currentState === state) {
    setter(filterState.collapsed)
  } else {
    setter(state);
  }
}

/**
 * Sort by widget.
 *
 * @param {Object} sorting
 * @param {String} customSortBy
 * @param {String} customSortByLabel
 * @param {String} collectionName
 * @returns {JSX.Element}
 * @constructor
 */
const SortByWidget = ({sortBy, customSortBy,customSortByLabel, collectionName}) => {
  if (!sortBy) {
    return <></>
  }

  const activeSortByFilters = getEnabled(sortBy);
  activeSortByFilters.sort(sortByWeight);

  let customSortByItem = null;
  const existingSortingKeys = [];
  if (customSortBy !== undefined && customSortBy.toString().trim().length > 0) {
    // E.g.: title:desc,created:desc
    const sortingValue = `${collectionName}/sort/${customSortBy}`;
    if (!customSortByLabel) {
      customSortByLabel = '';
    }
    customSortByItem = {
      value: sortingValue,
      label: customSortByLabel.toString().trim().length > 0 ? customSortByLabel : 'Default',
    }
    // Store sorting keys to avoid duplications.
    existingSortingKeys.push(sortingValue);
  }

  if (!activeSortByFilters.length && !customSortByItem) {
    return <></>;
  }

  const items = [];
  if (customSortByItem) {
    items.push(customSortByItem);
  }

  for (const f of activeSortByFilters) {
    if (f.enabled && f.label) {
      const sortingValue = `${collectionName}/sort/${f.fieldName}:asc`;
      if (!existingSortingKeys.includes(sortingValue)) {
        items.push({value: sortingValue, label: f.label})
        existingSortingKeys.push(sortingValue);
      }
    }

    if (f.sortingDesc && f.labelSortingDesc) {
      const sortingValue = `${collectionName}/sort/${f.fieldName}:desc`;
      if (!existingSortingKeys.includes(sortingValue)) {
        items.push({value: sortingValue, label: f.labelSortingDesc})
        existingSortingKeys.push(sortingValue);
      }
    }
  }

  if (!items.length) {
    return <></>;
  }
  const cssClasses = ['faceted-filters-form__sorting-widget']
  if (items.length === 1) {
    cssClasses.push('d-none');
  }
  return <div className={cssClasses.join(' ')}>
    <div className="faceted-filters-form__sorting-header--no-border">
      {I18n.t('faceted_search.filters.sort_by')}
    </div>
    <div className="faceted-filters-form__sorting-body--no-padding">
      <SortBy defaultRefinement={items[0].value} items={items} />
    </div>
  </div>
};

/**
 * Filters widget.
 *
 * @param {Object} filters
 * @param {Array} disabledFilters
 * @param {Function} closeFiltersHandler
 * @constructor
 */
const FiltersWidget = ({filters, disabledFilters, closeFiltersHandler}) => {
  if (!filters) {
    return <></>;
  }

  const activeFilters = getEnabled(filters, disabledFilters);
  activeFilters.sort(sortByWeight);
  if (!activeFilters) {
    return <></>;
  }

  const cssClasses = ['faceted-filters-form--widgets'];
  if (activeFilters.length === 1) {
    cssClasses.push('single-widget');
  }

  return <>
    <div className={cssClasses.join(' ')}>
      {activeFilters.map((value, key) => {
        let el = <div key={key}></div>
        switch (value.widget) {
          case 'menu_select':
            el =
              <FacetedSearchMenuSelect key={key}
                                       label={value.label || value.fieldName}
                                       attributeName={value.fieldName} />;
            break;
          case 'checkboxes':
            el = <FacetedSearchRefinementList key={key}
                                              header={value.label || value.fieldName}
                                              attributeName={value.fieldName} />;
            break;
        }
        return el;
      })}
    </div>
    <div className='apply-button-container'>
      <button type='button' className='apply-button' onClick={() => {closeFiltersHandler()}}>
        {I18n.t('faceted_search.filters.apply')}
      </button>
    </div>
  </>;
};

export const FacetedSearchSidebar = (props) => {
  const {sortBy, customSortBy, customSortByLabel} = props;
  const {filters, collectionName, disabledFilters} = props;
  const [currentFiltersState, setCurrentFiltersState] = useState(filterState.none);

  // Styling.
  const cssFiltersTogglerClasses = ['faceted-filters-form--filters_toggler'];
  switch (currentFiltersState) {
    case filterState.sortBy:
      cssFiltersTogglerClasses.push('sort-by');
      break;
    case filterState.filter:
      cssFiltersTogglerClasses.push('filter');
      break;
    case filterState.collapsed:
      cssFiltersTogglerClasses.push('collapsed');
      break;
    default:
      cssFiltersTogglerClasses.push('inactive');
      break;
  }

  const closeFiltersHandler = () => {
    handleFiltersToggling(currentFiltersState, setCurrentFiltersState, filterState.filter);
    document.getElementsByTagName('body')[0].classList.remove('fs-filter-visible');
  }

  return <div className="faceted-filters-form">
    <div className={cssFiltersTogglerClasses.join(' ')}>
      <div className={'text-search-panel text-search-panel--mobile'}>
        <FacetedSearchBox/>
      </div>
      <button type="button" className="btn-filter-toggler btn-filter" onClick={() => {
        handleFiltersToggling(currentFiltersState, setCurrentFiltersState, filterState.filter);
        document.getElementsByTagName('body')[0].classList.add('fs-filter-visible');
      }}>
        {I18n.t('faceted_search.filters.label')}
        <span className="filter-icon"><IconFilter/></span>
      </button>
    </div>

    <div className="faceted-filters-form--filters_overlay"></div>
    <div className="faceted-filters-form--filters_wrappers">
      <div className='faceted-filters-form__header'>
        <p className="h--smaller faceted-filters-form__title">
          {I18n.t('faceted_search.filters.label')}
        </p>
        <button type='button' className='faceted-filters-form__close-btn' onClick={() => {closeFiltersHandler()}}>
          <IconClose/>
        </button>
      </div>

      <div className={'text-search-panel text-search-panel--desktop'}>
        <FacetedSearchBox/>
      </div>

      <div className={`faceted-filters-form--filters_wrapper${currentFiltersState === filterState.sortBy ? ' visible' : ''}`}>
        <SortByWidget sortBy={sortBy}
                      customSortBy={customSortBy}
                      customSortByLabel={customSortByLabel}
                      collectionName={collectionName} />
      </div>

      <div className={`faceted-filters-form--filters_wrapper faceted-filters-list${currentFiltersState === filterState.filter ? ' visible' : ''}`}>
        <FiltersWidget disabledFilters={disabledFilters} filters={filters} closeFiltersHandler={() => {closeFiltersHandler()}} />
      </div>
    </div>
  </div>
}
